/*
*  Import external font families
*/

/* pt-sans-regular - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''), url('./pt-sans-v16-latin-regular.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*
 * Body resets
 *
 * Update the foundational and global aspects of the page.
 */

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html {
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}
@media (min-width: 38em) {
  html {
    font-size: 18px;
  }
}

body {
  color: #24292e;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}

/* No `:visited` state is required by default (browsers will use `a`) */
a {
  color: #268bd2;
  text-decoration: none;
}
a strong {
  color: inherit;
}
/* `:focus` is linked to `:hover` for basic accessibility */
a:hover,
a:focus {
  text-decoration: underline;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  letter-spacing: -.025rem;
  margin-bottom: .5rem;
  font-weight: 400;
  line-height: 1.25;
  color: #313131;
  text-rendering: optimizeLegibility;
}
h1 {
  font-size: 2rem;
}
h2 {
  margin-top: 1rem;
  font-size: 1.75rem;
}
h3 {
  margin-top: 1.5rem;
  font-size: 1.5rem;
}
h4 {
  margin-top: 1rem;
  font-size: 1.25rem;
}
h5, h6 {
  margin-top: 1rem;
  font-size: 1.10rem;
}

/* Body text */
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

strong {
  color: #303030;
}


/* Lists */
ul, ol, dl {
  margin-top: 0;
//   margin-bottom: 1rem;
}

dt {
  font-weight: bold;
}
dd {
  margin-bottom: .5rem;
}

/* Misc */
hr {
  position: relative;
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #fff;
}

abbr {
  font-size: 85%;
  font-weight: bold;
  color: #555;
  text-transform: uppercase;
}
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #e5e5e5;
}

/* Code */
code {
  padding: .25em .5em;
  font-size: 85%;
  color: #bf616a;
  background-color: #f9f9f9;
  border-radius: 3px;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: .8rem;
  line-height: 1.4;
  overflow-x: auto;
  background-color: #f9f9f9;
}
pre code {
  padding: 0;
  font-size: 100%;
  color: inherit;
  background-color: transparent;
}

/* Pygments via Jekyll */
.highlight {
  margin-bottom: 1rem;
  border-radius: 4px;
}
.highlight pre {
  margin-bottom: 0;
}

/* Quotes */
blockquote {
  padding: .5rem 1rem;
  margin: .8rem 0;
  color: #7a7a7a;
  border-left: .25rem solid #e5e5e5;
}
blockquote p:last-child {
  margin-bottom: 0;
}
@media (min-width: 30em) {
  blockquote {
    padding-right: 4rem;
    padding-left: 1.25rem;
  }
}

img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

/* Tables */
table {
  margin-bottom: 1rem;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
  table-layout: fixed;
}
td,
th {
  padding: .25rem .5rem;
  border: 1px solid #e5e5e5;
}
tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}

figure {
  margin-left: 0rem;
}



/*
 * Wrapper
 *
 * The wrapper is used to position site content when the sidebar is toggled. We
 * use an outter wrap to position the sidebar without interferring with the
 * regular page content.
 */

.wrap {
  position: relative;
  width: 100%;
}


/*
 * Container
 *
 * Center the page content.
 */

.container {
  max-width: 28rem;
  padding-left:  1rem;
  padding-right: 1rem;
  margin-left:  auto;
  margin-right: auto;
}
@media (min-width: 38em) {
  .container {
    max-width: 36rem;
    padding-left:  2rem;
  }
}
@media (min-width: 58em) {
  .container {
    max-width: 50rem;
  }
}


/*
 * Masthead
 *
 * Super small header above the content for site name and short description.
 */

.masthead {
  padding-top:    1rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #eee;
  border-top: 5px solid #424242
}
.masthead-title {
  margin-top: 0;
  margin-bottom: 0;
  color: #505050;
}
.masthead-title a {
  color: #505050;
  font-size: 1.5rem;
  margin-right: 0.25rem;
}
.masthead-title small {
  font-size: 75%;
  font-weight: 400;
  color: #c0c0c0;
  letter-spacing: 0;
}

@media (max-width: 50em) {
  .masthead-title {
    text-align: center;
  }
  .masthead-title small {
    display: none;
  }
}


/*
 * Sidebar
 *
 * The sidebar is the drawer, the item we are toggling with our handy hamburger
 * button in the corner of the page.
 *
 * This particular sidebar implementation was inspired by Chris Coyier's
 * "Offcanvas Menu with CSS Target" article, and the checkbox variation from the
 * comments by a reader. It modifies both implementations to continue using the
 * checkbox (no change in URL means no polluted browser history), but this uses
 * `position` for the menu to avoid some potential content reflow issues.
 *
 * Source: http://css-tricks.com/off-canvas-menu-with-css-target/#comment-207504
 */

/* Style and "hide" the sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -14rem;
  width: 14rem;
  visibility: hidden;
  overflow-y: auto;
  font-size: .875rem; /* 15px */
  color: rgba(255,255,255,.6);
  background-color: #202020;
  -webkit-transition: all .3s ease-in-out;
          transition: all .3s ease-in-out;
}
@media (min-width: 30em) {
  .sidebar {
    font-size: .75rem; /* 14px */
  }
}

/* Sidebar content */
.sidebar a {
  font-weight: normal;
  color: #fff;
}
.sidebar-item {
  padding: 1rem;
}
.sidebar-item p:last-child {
  margin-bottom: 0;
}

/* Sidebar nav */
.sidebar-nav {
  border-bottom: 1px solid rgba(255,255,255,.1);
}
.sidebar-nav-item {
  display: block;
  padding: .5rem 1rem;
  border-top: 1px solid rgba(255,255,255,.1);
}
.sidebar-nav-item.active,
a.sidebar-nav-item:hover,
a.sidebar-nav-item:focus {
  text-decoration: none;
  background-color: rgba(255,255,255,.1);
  border-color: transparent;
}

@media (min-width: 50em) {
  .sidebar-item {
    padding: 1.5rem;
  }
  .sidebar-nav-item {
    padding-left:  1.5rem;
    padding-right: 1.5rem;
  }
}

/* Hide the sidebar checkbox that we toggle with `.sidebar-toggle` */
.sidebar-checkbox {
  display: none;
}

/* Style the `label` that we use to target the `.sidebar-checkbox` */
.sidebar-toggle {
  position: absolute;
  top:  .8rem;
  left: 1rem;
  display: block;
  padding: .25rem .75rem;
  color: #505050;
  background-color: #fff;
  border-radius: .25rem;
  cursor: pointer;
}

.sidebar-toggle:before {
  display: inline-block;
  width: 1rem;
  height: .75rem;
  content: "";
  background-image: -webkit-linear-gradient(to bottom, #555, #555 20%, #fff 20%, #fff 40%, #555 40%, #555 60%, #fff 60%, #fff 80%, #555 80%, #555 100%);
  background-image:    -moz-linear-gradient(to bottom, #555, #555 20%, #fff 20%, #fff 40%, #555 40%, #555 60%, #fff 60%, #fff 80%, #555 80%, #555 100%);
  background-image:     -ms-linear-gradient(to bottom, #555, #555 20%, #fff 20%, #fff 40%, #555 40%, #555 60%, #fff 60%, #fff 80%, #555 80%, #555 100%);
  background-image:         linear-gradient(to bottom, #555, #555 20%, #fff 20%, #fff 40%, #555 40%, #555 60%, #fff 60%, #fff 80%, #555 80%, #555 100%);
}

.sidebar-toggle:active,
#sidebar-checkbox:focus ~ .sidebar-toggle,
#sidebar-checkbox:checked ~ .sidebar-toggle {
  color: #fff;
  background-color: #555;
}

.sidebar-toggle:active:before,
#sidebar-checkbox:focus ~ .sidebar-toggle:before,
#sidebar-checkbox:checked ~ .sidebar-toggle:before {
  background-image: -webkit-linear-gradient(to bottom, #fff, #fff 20%, #555 20%, #555 40%, #fff 40%, #fff 60%, #555 60%, #555 80%, #fff 80%, #fff 100%);
  background-image:    -moz-linear-gradient(to bottom, #fff, #fff 20%, #555 20%, #555 40%, #fff 40%, #fff 60%, #555 60%, #555 80%, #fff 80%, #fff 100%);
  background-image:     -ms-linear-gradient(to bottom, #fff, #fff 20%, #555 20%, #555 40%, #fff 40%, #fff 60%, #555 60%, #555 80%, #fff 80%, #fff 100%);
  background-image:         linear-gradient(to bottom, #fff, #fff 20%, #555 20%, #555 40%, #fff 40%, #fff 60%, #555 60%, #555 80%, #fff 80%, #fff 100%);
}

@media (min-width: 30.1em) {
  .sidebar-toggle {
    position: fixed;
  }
}

@media print {
  .sidebar-toggle {
    display: none;
  }
}

/* Slide effect
 *
 * Handle the sliding effects of the sidebar and content in one spot, seperate
 * from the default styles.
 *
 * As an a heads up, we don't use `transform: translate3d()` here because when
 * mixed with `position: fixed;` for the sidebar toggle, it creates a new
 * containing block. Put simply, the fixed sidebar toggle behaves like
 * `position: absolute;` when transformed.
 *
 * Read more about it at http://meyerweb.com/eric/thoughts/2011/09/12/.
 */

.wrap,
.sidebar,
.sidebar-toggle {
  -webkit-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
          backface-visibility: hidden;
}
.wrap,
.sidebar-toggle {
  -webkit-transition: -webkit-transform .3s ease-in-out;
          transition: transform .3s ease-in-out;
}

#sidebar-checkbox:checked + .sidebar {
  z-index: 10;
  visibility: visible;
}
#sidebar-checkbox:checked ~ .sidebar,
#sidebar-checkbox:checked ~ .wrap,
#sidebar-checkbox:checked ~ .sidebar-toggle {
  -webkit-transform: translateX(14rem);
      -ms-transform: translateX(14rem);
          transform: translateX(14rem);
}


/*
 * Posts and pages
 *
 * Each post is wrapped in `.post` and is used on default and post layouts. Each
 * page is wrapped in `.page` and is only used on the page layout.
 */

.post {
  margin-bottom: 2em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid #eee;
}

/* Blog post or page title */
.page-title,
.post-title,
.post-title a {
  color: #303030;
}
.page-title,
.post-title {
  margin-top: 0;
}

/* Meta data line below post title */
.post-date {
  display: block;
  margin-top: -.5rem;
  margin-bottom: 1rem;
  color: #9a9a9a;
}


/* Related posts */
.related {
  padding-bottom: 2rem;
}
.related-posts {
  padding-left: 0;
  list-style: none;
}
.related-posts h3 {
  margin-top: 0;
}
.related-posts li small {
  font-size: 75%;
  color: #999;
}
.related-posts li a:hover {
  color: #268bd2;
  text-decoration: none;
}
.related-posts li a:hover small {
  color: inherit;
}

/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid lighten(#828282, 40%);
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #eee;
  padding: 20px 0;
  text-align: center;
}

.read-more {
  text-transform: uppercase;
  font-size: 15px;
}

.comments {
  margin-bottom: 10px;
}


/*
 * Pagination
 *
 * Super lightweight (HTML-wise) blog pagination. `span`s are provide for when
 * there are no more previous or next posts to show.
 */

.pagination {
  overflow: hidden; /* clearfix */
  margin-left: -1rem;
  margin-right: -1rem;
  color: #ccc;
  text-align: center;
}

/* Pagination items can be `span`s or `a`s */
.pagination-item {
  display: block;
  padding: 1rem;
  border: 1px solid #eee;
}
.pagination-item:first-child {
  margin-bottom: -1px;
}

/* Only provide a hover state for linked pagination items */
a.pagination-item:hover {
  background-color: #f5f5f5;
}

@media (min-width: 30em) {
  .pagination {
    margin: 3rem 0;
  }
  .pagination-item {
    float: left;
    width: 50%;
  }
  .pagination-item:first-child {
    margin-bottom: 0;
    border-top-left-radius:    4px;
    border-bottom-left-radius: 4px;
  }
  .pagination-item:last-child {
    margin-left: -1px;
    border-top-right-radius:    4px;
    border-bottom-right-radius: 4px;
  }
}


/*
 * Messages
 *
 * Show alert messages to users. You may add it to single elements like a `<p>`,
 * or to a parent if there are multiple elements to show.
 */

.message {
  margin-bottom: 1rem;
  padding: 1rem;
  color: #717171;
  background-color: #f9f9f9;
}

/*
 * Custom type
 *
 * Extend paragraphs with `.lead` for larger introductory text.
 */

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.input-form {
  label {
    width: 4.5rem;
    margin: 5px 0;
    display: inline-block;
    vertical-align: top;
  }
  label.robot-label {
      width: 4rem;
      margin-right: 0.5rem;
  }
  input, textarea, button {
    margin: 5px 0;
    padding: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #bbb;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;

    &:focus {
      outline-color: #268bd2;
    }
  }
  input.field, textarea {
    min-width: 16rem;
  }
  input[name=subject], textarea {
    width: 74%;
  }
  textarea {
    height: 150px;
  }
  button {
    border: 0;
    background-color: #268bd2;
    padding: 10px 22px;
    color: #fff;
    margin-top: 20px;
    cursor: pointer;

    &:hover {
      background-color: darken(#268bd2, 10%);
      color: darken(#fff, 10%);
    }
  }
}

.avatar {
  img {
    width: 60%;
  }
}

#topcontrol {
  @media (max-width: 38rem) {
      display: none;
  }
  
  position: fixed;
  bottom: 10px;
  right: 20px;
  opacity: 0;
  cursor: pointer;
  -webkit-transition: opacity 400ms ease-in-out;
  -moz-transition: opacity 400ms ease-in-out;
  -o-transition: opacity 400ms ease-in-out;
  transition: opacity 400ms ease-in-out;
}

#search_results {
  padding-left: 20px;
  em {
    color: #bf616a;
    background-color: #f9f9f9;
    border-radius: 3px;
    font-style: normal;
  }
}

.info-block {
  padding: .5rem 1rem;
  margin: .8rem 0;
  border-left: .25rem solid #bee5eb;
  background-color: #d1ecf1;
  color: #0c5460;
  a {
    color: #0c7af0;
  }
}
.info-block p:last-child {
  margin-bottom: 0;
}
@media (min-width: 30em) {
    .info-block {
      padding-right: 4rem;
      padding-left: 1.25rem;
    }
}

.search-icon {
    width: 1.25rem;
    float: right;
    margin-top: 0.5rem;
}